<template>
  <v-dialog v-model="dialog" persistent max-width="680">
    <v-card :key="key">
      <v-app-bar flat color="primary">
        <v-toolbar-title class="text-h6 white--text pl-0">
          {{ $t("received_orders") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="$emit('close')" :title="$t('Cancel')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text style="max-height: 60vh; overflow-y: auto">
        <v-row class="pt-4">
          <v-col cols="12" sm="12">
            <v-list class="grey lighten-4" subheader two-line>
              <template v-if="orders.length > 0">
                <template v-for="(item, index) in orders">
                  <v-list-item
                    :key="item.local_reference"
                    @click="accept(item)"
                    :disabled="item.id == currentorderId"
                    
                  >
                    <v-list-item-avatar>
                      <v-icon class="blue" dark>mdi-clipboard-text</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-if="item.name_table">
                        {{ $t("table") }}:
                        <v-chip small color="blue" dark>
                          {{ item.name_table }}
                        </v-chip>
                      </v-list-item-title>
                      <v-list-item-title
                        v-if="item.data_seller && item.data_seller.name"
                      >
                        {{ $t("Seller") + ": " + item.data_seller.name }}
                      </v-list-item-title>
                      <!--v-list-item-title v-if="item.data_customer && item.data_customer.name">
                                {{ $t('Customer') + ': ' + item.data_customer.name }}
                              </v-list-item-title-->
                      <v-list-item-title
                        v-if="
                          item.data_domiciliary && item.data_domiciliary.name
                        "
                      >
                        {{
                          $t("Domiciliary") + ": " + item.data_domiciliary.name
                        }}
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        {{ $filters.fromNow(item.opening_date) }}
                      </v-list-item-subtitle>                      

                      <v-list-item-subtitle>
                        {{ $filters.currency(item.total_to_pay, 0) }}
                      </v-list-item-subtitle>

                      <v-list-item-subtitle v-if="item.local_reference">
                        {{ ((item.local_reference)) }}  
                      </v-list-item-subtitle>

                    </v-list-item-content>
                    <v-list-item-action >
                      <v-btn plain elevation="0" :loading="item.id == currentorderId">
                        Aceptar
                        <v-icon color="grey lighten-1"
                          >mdi-chevron-right</v-icon
                        >
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider
                    v-if="index < orders.length - 1"
                    :key="index"
                  ></v-divider>
                </template>
              </template>
              <v-list-item v-else>
                <v-list-item-avatar>
                  <v-icon class="red" dark>mdi-close-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("no_pending_orders") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!--{{order.data_items}}-->
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          dark
          color="primary"
          @click="$emit('close')"
          :title="$t('Cancel')"
        >
          {{ $t("Cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
.bg_modal {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)) 0% 0% /
    cover no-repeat;
}
</style>
<script>
export default {
  components: {},
  name: "CartPendingOrdersReceived",
  data: () => ({
    orders: [],
    key: 0,
    currentorderId: null
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {

  },
  methods: {
    async getPendingOrders() {
      this.orders = [];
      this.orders = await this.$pos.getOpenedOrdersToReceive();
   
    },
    cancel() {
      this.orders = [];
      this.searched = false;
      this.key++;
      this.$emit("cancel");
    },
    clear() {
      this.orders = [];
      this.item = JSON.parse(JSON.stringify(DEFAULT_ITEM));
    },
    accept(item) {
      //this.$pos.setCurrentOrder(item);
      //this.$emit("cancel");
      this.currentorderId = item.id;
      this.$http
        .post("api/v1/order/change-status/" + item.id + "", {
          order_status: "received",
          time: this.$filters.currentDatetime()
        })
        .then((response) => {
          if (response && response.success) {
            let indexToRemove = this.orders.findIndex(
              (el) => el.id === item.id
            );
            // Remueve el elemento del array usando splice
            if (indexToRemove !== -1) {
              this.orders.splice(indexToRemove, 1);
            }
            if(this.orders.length === 0){
              this.$store.commit("setOrderReceived", false);
            }
            //item['order_status'] = "received";
            //this.$db.updateOrders([item]);
            this.$store.commit('createdOrder', true)

            //this.$emit("order:acepted")
            /*let installation = this.$store.getters.installation;
            addChangeStatus(installation.subsidiary_id, {
              order: item.id,
              st: "received",
            });*/
          }
        });
    },
  },
};
</script>